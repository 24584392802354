.button {
  position: absolute;
  z-index: 3;
  background: #23ad25;
  background-image: linear-gradient(to bottom, #23ad25, #b4d1af);
  border-radius: 10px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 100;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #3c7020 1px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  height: 41px;
  width: 100px;
  margin-left: calc(50% - 60px);
}

.button:hover {
  border: solid #1d922e 1px;
  background: #1ed03a;
  background-image: linear-gradient(to bottom, #1ed03a, #6ed329);
  border-radius: 10px;
  text-decoration: none;
}
