.buttonCdn {
  background: #23ad25;
  background-image: linear-gradient(to bottom, #23ad25, #5dd345);
  border-radius: 10px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 100;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #3c7020 1px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  height: 41px;
  display: flex;
  align-items: center;
  width: 76px;
}

.buttonCdn:hover {
  border: solid #1d922e 1px;
  background: #1ed03a;
  background-image: linear-gradient(to bottom, #1ed03a, #6ed329);
  border-radius: 10px;
  text-decoration: none;
}
.buttonCdn:disabled {
  background: #31551d;
  color: gray;
}

.buttonCdn:disabled:hover {
  border: solid #3c7020 1px;
}
