.page {
  height: 100%;
  overflow: auto;
}

.body {
  display: flex;
}

.tabsContainer {
  width: 25vh;
  border: 1px solid lightgray;
  height: max-content;
}

.frameContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.buttonsContainer {
  padding-left: 16px;
  padding-bottom: 8px;
}

.tabPanelBox {
  padding: 20px;
}
