.selectCss {
  flex: 2;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  padding: 0.6em 0.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 5px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.2em;
  appearance: none;
  background-color: rgb(231, 231, 231);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  background-image: linear-gradient(45deg, transparent 50%, #777 50%),
    linear-gradient(135deg, #777 50%, transparent 50%);
  background-position: right 15px top 50%, right 10px top 50%;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.selectCss:disabled {
  cursor: not-allowed;
  color: gray;
  fill: gray;
  background-color: #d3d3d3;
}

.selectCss:hover {
  border-color: #888;
}

.selectCss:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
  color: #222;
  outline: none;
}

.selectCss option {
  font-size: 12px;
  background-color: white;
}

.selectCss optgroup {
  font-weight: bold;
  background-color: rgb(215, 215, 215);
  font-size: 13px;
}

.label {
  line-height: 3;
  flex: 1;
  font-family: sans-serif;
  font-weight: bold;
  user-select: none;
}
