.toothNumText {
  position: relative;
  left: 10px;
  top: -11px;
  border: 1px solid black;
  padding: 3px 0px;
  width: 100px;
  text-align: center;
}

.arrow {
  position: relative;
  height: 27px;
  width: 72px;
  left: 10px;
}

.table {
  padding-left: 15px;
}

.flexContainer {
  display: flex;
  height: 153px;
}

.arrows {
  display: flex;
  margin: -20px 0 0 25px;
}

.addFinding {
  border: 2px solid #00adef;
  position: relative;
  top: 70px;
  left: 40px;
  background: #00adef;
  background-image: linear-gradient(to bottom, #00adef, #63c4eb);
  border-radius: 10px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 100;
  padding: 10px 20px 10px 20px;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid rgb(0 173 239) 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}
