.upperJaw {
  width: 182px;
  height: 162px;
  background-size: 176px 156px;
  background-position: left 2px top 2px;
  background-repeat: no-repeat;
}

.upperJaw::after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
}

.isDefault {
  background-image: url('./images/upper.svg');
}

.isDisabled::after {
  width: 180px;
  height: 160px;
  background-image: url('./images/upperdisabled.svg');
  background-size: 180px 160px;
  top: 0;
  left: 0;
}

.isSelected::after {
  width: 180px;
  height: 160px;
  background-image: url('./images/upperselected.svg');
  background-size: 180px 160px;
  top: 0px;
  left: 0px;
}
