.textArea {
  font-family: sans-serif;
  width: 92%;
  min-height: 20%;
  border: 2px solid rgb(170, 170, 170);
  padding: 2%;
  z-index: 5;
}

.dialogTextArea {
  width: 75%;
  font-family: monospace, monospace;
  height: 70%;
  margin-left: 1%;
  margin-right: 1%;
  font-size: 17px;
}

.buttons {
  display: flex;
  width: 50%;
}
h3 {
  color: #037082;
  font-weight: 500;
}

.button {
  display: block;
  margin-top: 3%;
  outline: none;
  background: #eee;
  border-color: rgb(170, 170, 170);
  padding: 5px;
  color: #037082;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5%;
}

.popupInner {
  position: absolute;
  width: 50%;
  height: 85%;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  border-radius: 10px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.popupContent {
  position: absolute;
  margin-left: 9%;
  height: 100%;
  width: 100%;
}
