.header {
  font-size: 20px;
  position: relative;
  padding-left: 20px;
  top: 0;
  border-top: 1px solid rgb(155, 155, 155);
  border-bottom: 1px solid rgb(155, 155, 155);
  display: flex;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 5px;
}

.paragraph {
  color: #037082;
  font-weight: 500;
}

.roleStyle {
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 23px;
  border-color: #037082;
  color: #037082;
  padding: 0.2em 0.2em 0.2em 0.2em;
  cursor: pointer;
}

.roleStyle:focus {
  border-color: #037082;
  color: #037082;
}

.roleStyle optgroup {
  background-color: #037082;
}

.roleStyle option {
  font-size: 14px;
}

.button {
  position: relative;
  display: block;
  outline: none;
  background: #eee;
  border-color: rgb(170, 170, 170);
  padding: 5px;
  color: #037082;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 80%;
  margin-top: 22px;
}
