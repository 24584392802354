.marker {
  position: absolute;
  border: 1px solid black;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
}

.markerMissPoint {
  z-index: 1;
  position: absolute;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 0, 0);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.blink {
  animation: blink infinite;
  animation-duration: 1s;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.marker:hover {
  background-color: tomato;
}
