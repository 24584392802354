.container {
  display: flex;
  width: fit-content;
  flex-direction: column;
  padding-left: 15px;
}

.groupedDropDownListContainer {
  flex: 1;
  width: 350px;
}

.disabled {
  opacity: 0.2;
}
