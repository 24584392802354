.selected {
  background-color: rgb(37, 97, 168) !important;
  font-weight: bold;
  color: white;
  animation-name: backgroundChange;
  animation-duration: 0.5s;
}

.disabledRow {
  opacity: 0.3;
}

@keyframes backgroundChange {
  from {
    background-color: #f2f2f2;
  }
  to {
    background-color: rgb(37, 97, 168);
  }
}

.row {
  background-color: white;
  font-family: sans-serif;
}
.row:nth-child(even) {
  background-color: #f2f2f2;
}

.header {
  padding: 5px 0 5px 0;
  font-family: sans-serif;
  color: white;
  text-transform: uppercase;
  background-color: rgb(105, 157, 220);
  border-bottom: 1px solid darkblue;
}

.cell {
  white-space: normal;
}
