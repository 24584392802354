.textHeader {
  font-family: sans-serif;
  font-weight: bold;
  margin-left: 48px;
}

.textArea {
  font-family: sans-serif;
  font-size: 14px;
  resize: none;
}

.wrapper {
  display: flex;
}

.headerButtonsContainer {
  display: flex;
  flex-direction: row;
}
