.taskButton {
  width: 92px;
  margin: 0.5px;
}

.pressable {
  cursor: pointer;
}

.selectedTask {
  background: #2561a8;
  color: white;
}

.consistentTask {
  background-image: linear-gradient(to bottom, #23ad25ad, #5dd3455e);
}

.inconsistentTask {
  background-image: linear-gradient(to bottom, #ac00005e, #7e00005e);
}
