.button {
  background: #5e6064;
  background-image: linear-gradient(to bottom, #b8c4d1, #5a5d61);
  border-radius: 10px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 100;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #a8aaae 1px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  height: 41px;
  width: 145px;
  margin: 10px;
}
.buttons {
  display: flex;
  padding-left: 50px;
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
