.tabForm {
  width: 100%;
}

.stepper {
  height: 44px;
}

.stepForm {
  width: 100%;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  border: 2px solid lightgray;
  border-radius: 10px;
  overflow-y: scroll;
  height: 55vh;
}

.stepForm::-webkit-scrollbar {
  width: 8px;
}

.stepForm::-webkit-scrollbar-track {
  background: #00000000;
}

.stepForm::-webkit-scrollbar-thumb {
  background: #818181;
}

.stepForm::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}

.buttonBack {
  margin-right: 8px;
}

.buttonSpacer {
  flex: 1 1 auto;
}

.hourglassProgress {
  display: flex;
  justify-content: center;
  padding-top: 25vh;
}

.hiddenBtn {
  display: none;
}

.visibleBtn {
  display: inline-block;
}
