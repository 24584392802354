.customUi {
  font-family: Arial, Helvetica, sans-serif;
  min-width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.dialogBtns {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.dialogBtn {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.dialogTextArea {
  width: 100%;
  resize: none;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: #666;
}

.validationError {
  color: red;
  font-size: 12px;
  height: 3px;
}
