.notesHeader {
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 1px;
  padding-left: 0px;
}

.notesTextArea {
  width: 230px;
  height: 46px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.2em;
  margin-left: 49px;
}

.notes {
  position: relative;
  flex-direction: column;
}

.notesContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-left: 240px;
  margin-top: 16px;
}

.notesNavigation {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
