.popupInner {
  position: absolute;
  width: 40%;
  height: 85%;
  left: 30%;
  right: 30%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  border-radius: 10px;
  overflow: scroll;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.popupContent {
  position: absolute;
  margin-left: 9%;
  margin-right: 9%;
  width: 80%;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  margin-left: 7px;
}

.linkButton {
  background: aqua;
  display: inline-block;
  width: 20px;
  /* Adjust the width and height to make it circular */
  height: 20px;
  border-radius: 45%;
  /* Creates a circular border */
  text-align: center;
  line-height: 20px;
  /* Centers the content vertically */
  text-decoration: none;
  border: 2px solid #007bff;
  /* Replace with your desired border color */
  color: #007bff;
  /* Replace with your desired text color */
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 20px;
}
