.buttonCdn {
  background: #e24509;
  background-image: linear-gradient(to bottom, #e24509, #f86930);
  border-radius: 10px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 100;
  text-shadow: 1px 1px 20px #ffffff;
  border: solid #a41616 1px;
  text-decoration: none;
  cursor: pointer;
  justify-content: center;
  height: 41px;
  display: flex;
  align-items: center;
  width: 76px;
  z-index: 1;
}

.buttonCdn:hover {
  border: solid #e24509 1px;
  background: #e24509;
  background-image: linear-gradient(to bottom, #e24509, #f86930);
  border-radius: 10px;
  text-decoration: none;
}
.buttonCdn:disabled {
  background: #792f12;
  color: gray;
}

.buttonCdn:disabled:hover {
  border: solid #792f12 1px;
}
