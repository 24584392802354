.clickableCell {
  cursor: pointer;
}

.hiddenTableRow {
  visibility: hidden;
}

.usersTable {
  height: 100%;
}

.usersTable::-webkit-scrollbar {
  width: 8px;
}

.usersTable::-webkit-scrollbar-track {
  background: #00000000;
}

.usersTable::-webkit-scrollbar-thumb {
  background: #818181;
}

.usersTable::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollableContainer {
  max-height: 400px;
  overflow-y: auto;
}

.groupFilter {
  margin-bottom: 8px;
  width: 50%;
}
