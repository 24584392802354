.dot {
  width: 7px;
  height: 6px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
}

.isPointDefined {
  background-color: #89d1f4;
}

.isNotMarkable {
  background-color: #87919e;
}

.ada1 {
  margin-left: 29px;
  margin-top: 141px;
}

.ada2 {
  margin-left: 29px;
  margin-top: 117px;
}

.ada3 {
  margin-left: 34px;
  margin-top: 92px;
}

.ada4 {
  margin-left: 38px;
  margin-top: 73px;
}

.ada5 {
  margin-left: 45px;
  margin-top: 53px;
}

.ada6 {
  margin-left: 52px;
  margin-top: 41px;
}

.ada7 {
  margin-left: 60px;
  margin-top: 31px;
}

.ada8 {
  margin-left: 76px;
  margin-top: 28px;
}

.ada9 {
  margin-left: 94px;
  margin-top: 29px;
}

.ada10 {
  margin-left: 111px;
  margin-top: 32px;
}

.ada11 {
  margin-left: 120px;
  margin-top: 42px;
}

.ada12 {
  margin-left: 127px;
  margin-top: 54px;
}

.ada13 {
  margin-left: 135px;
  margin-top: 72px;
}
.ada14 {
  margin-left: 138px;
  margin-top: 91px;
}
.ada15 {
  margin-left: 143px;
  margin-top: 117px;
}
.ada16 {
  margin-left: 144px;
  margin-top: 142px;
}
.ada17 {
  margin-left: 143px;
  margin-top: 15px;
}
.ada18 {
  margin-left: 140px;
  margin-top: 42px;
}
.ada19 {
  margin-left: 136px;
  margin-top: 69px;
}
.ada20 {
  margin-left: 132px;
  margin-top: 91px;
}
.ada21 {
  margin-left: 123px;
  margin-top: 110px;
}
.ada22 {
  margin-left: 113px;
  margin-top: 121px;
}
.ada23 {
  margin-left: 103px;
  margin-top: 126.5px;
}
.ada24 {
  margin-left: 92px;
  margin-top: 128.5px;
}
.ada25 {
  margin-left: 80px;
  margin-top: 129px;
}
.ada26 {
  margin-left: 68px;
  margin-top: 127px;
}
.ada27 {
  margin-left: 56px;
  margin-top: 119px;
}
.ada28 {
  margin-left: 48px;
  margin-top: 108px;
}
.ada29 {
  margin-left: 39px;
  margin-top: 92px;
}
.ada30 {
  margin-left: 35px;
  margin-top: 70px;
}
.ada31 {
  margin-left: 31px;
  margin-top: 41px;
}
.ada32 {
  margin-left: 28px;
  margin-top: 14px;
}
