.container {
  display: flex;
  width: fit-content;
  flex-direction: column;
}
.groupedDropDownListContainer {
  flex: 1;
  width: 350;
}
.buttonsContainer {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}
.groupsContainer {
  display: flex;
}
.button {
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 12px;
  border-radius: 3px;
  color: white;
  text-transform: capitalize;
  margin: 5px;
}

.submitButton {
  background-color: rgb(36, 151, 62);
}
.submitButton:hover {
  background-color: rgb(27, 116, 47);
}
.deleteButton {
  background-color: rgb(211, 37, 54);
}
.deleteButton:hover {
  background-color: rgb(156, 27, 39);
}
