.labelingHeader {
  display: flex;
}

.currentImage {
  font-weight: bold;
  margin: 5px;
}

.currentImageTier2 {
  margin-left: 290px;
  position: absolute;
  white-space: nowrap;
}

.headerBtns {
  display: flex;
  position: absolute;
  margin-left: 160px;
}

.headerBtnsImage {
  margin-top: 53px;
}

.multipleHeaderBtns {
  margin-top: 48px;
}

.deleteBtn {
  background: #e24509;
  background-image: linear-gradient(to bottom, #e24509, #f86930);
  border: solid #f84600 1px;
  border-radius: 10px;
  color: white;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 100;
  padding: 5px 10px 5px 10px;
  text-shadow: 1px 1px 20px #ffffff;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  width: 138px;
  height: 41px;
}

.deleteBtn:disabled {
  background: #792f12;
  color: gray;
}

.deleteSingleBtn {
  margin-left: 4px;
}

.returnToWorkBtn {
  margin-right: 2px;
  width: 4px;
}

.saveBtn {
  height: 44px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.saveBtnTooth {
  width: 76px;
  height: 151px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.saveContainer {
  min-height: 41px;
}
